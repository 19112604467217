import { AnyAction } from 'redux'
import {
  AllocationRoomsType,
  AllocationsType,
  DragHistoryType,
  DragType,
} from '@/redux/allocations/types'

const actions = {
  GET_ROOMS_WITH_ATTENDANTS_REQUEST: 'allocations/GET_ROOMS_WITH_ATTENDANTS_REQUEST',
  SET_STATE: 'allocations/SET_STATE',
  SET_FILTERS: 'allocations/SET_FILTERS',
  DRAG_ELEMENT: 'allocations/DRAG_ELEMENT',
  CLEAR_ALLOCATED: 'allocations/CLEAR_ALLOCATED',
  UPDATE_STATE: 'allocations/UPDATE_STATE',
  UPDATE_STATE_SIDEBAR: 'allocations/UPDATE_STATE_SIDEBAR',
  REVERT_CHANGES: 'allocations/REVERT_CHANGES',
  GET_AUTO_ALLOCATE: 'allocations/GET_AUTO_ALLOCATE',
  GET_MULTI_ALLOCATE: 'allocations/GET_MULTI_ALLOCATE',
  SET_AUTO_ALLOCATE: 'allocations/SET_AUTO_ALLOCATE',
  SAVE_CHANGES: 'allocations/SAVE_CHANGES',
  SKIP_AND_SEND: 'allocations/SKIP_AND_SEND',
  CLEAR_ALL: 'allocations/CLEAR_ALL',
  START_ALLOCATION_SOCKET_CHANNEL: 'allocations/START_ALLOCATION_SOCKET_CHANNEL',
  CLOSE_ALLOCATION_SOCKET_CHANNEL: 'allocations/CLOSE_ALLOCATION_SOCKET_CHANNEL',
}

export const startAllocatonSocketChannel = (id?: string): AnyAction => {
  return {
    type: actions.START_ALLOCATION_SOCKET_CHANNEL,
    id,
  }
}

export const stopAllocatonSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_ALLOCATION_SOCKET_CHANNEL,
  }
}

export const getRoomsWithAttendants = (hotelID: string, date: string, type?: string): AnyAction => {
  return {
    type: actions.GET_ROOMS_WITH_ATTENDANTS_REQUEST,
    payload: { hotelID, date, type },
  }
}

export const dragElement = (data: DragType): AnyAction => {
  return {
    type: actions.DRAG_ELEMENT,
    payload: data,
  }
}

export const clearAllocated = (): AnyAction => {
  return {
    type: actions.CLEAR_ALLOCATED,
  }
}

export const updateState = (data: any): AnyAction => {
  return {
    type: actions.UPDATE_STATE,
    payload: data,
  }
}

export const revertChanges = (): AnyAction => {
  return {
    type: actions.REVERT_CHANGES,
  }
}

export const setFilters = (filters: { [k in string]: any[] | unknown }): AnyAction => {
  return {
    type: actions.SET_FILTERS,
    payload: filters,
  }
}

export const setRoomsWithAttendantsSuccess = (payload: AllocationsType): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const setRoomsAttendantsSuccess = (payload: AllocationRoomsType): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { ...payload, history: {} },
  }
}

export const getAutoAllocate = (hotelID: string, type: string): AnyAction => {
  return {
    type: actions.GET_AUTO_ALLOCATE,
    payload: { hotelID, type },
  }
}

export const setAutoAllocate = (payload: {
  allocated: Record<string, string[]>
  history: DragHistoryType
}): AnyAction => {
  return {
    type: actions.SET_AUTO_ALLOCATE,
    payload,
  }
}

export const saveChanges = (
  HotelID: string,
  date: string,
  statusType: string,
  { afterSubmit }: { afterSubmit?: () => void },
): AnyAction => {
  return {
    type: actions.SAVE_CHANGES,
    data: { HotelID, date, statusType, afterSubmit },
  }
}

export const skipAndSend = (): AnyAction => {
  return {
    type: actions.SKIP_AND_SEND,
  }
}

export const saveSkipped = (allocated: Record<string, string[]>): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { allocated },
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const updating = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { updating: data },
  }
}

export const clearAll = (): AnyAction => {
  return {
    type: actions.CLEAR_ALL,
  }
}

export default actions
