import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const LocationIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8337 14.8H16.5001C16.8867 14.8 17.2001 15.1134 17.2001 15.5C17.2001 15.8866 16.8867 16.2 16.5001 16.2H1.50005C1.11345 16.2 0.800049 15.8866 0.800049 15.5C0.800049 15.1134 1.11345 14.8 1.50005 14.8H3.16704V2.16667C3.16704 1.72464 3.34264 1.30072 3.6552 0.988155C3.96776 0.675595 4.39168 0.5 4.83371 0.5H13.167C13.6091 0.5 14.033 0.675595 14.3456 0.988155C14.6581 1.30072 14.8337 1.72464 14.8337 2.16667V14.8ZM6.50005 4.16666C6.22391 4.16666 6.00005 4.39052 6.00005 4.66666C6.00005 4.94281 6.22391 5.16666 6.50005 5.16666H7.33338C7.60953 5.16666 7.83338 4.94281 7.83338 4.66666C7.83338 4.39052 7.60953 4.16666 7.33338 4.16666H6.50005ZM6.00005 8C6.00005 7.72386 6.22391 7.5 6.50005 7.5H7.33338C7.60953 7.5 7.83338 7.72386 7.83338 8C7.83338 8.27614 7.60953 8.5 7.33338 8.5H6.50005C6.22391 8.5 6.00005 8.27614 6.00005 8ZM6.50005 10.8333C6.22391 10.8333 6.00005 11.0572 6.00005 11.3333C6.00005 11.6095 6.22391 11.8333 6.50005 11.8333H7.33338C7.60953 11.8333 7.83338 11.6095 7.83338 11.3333C7.83338 11.0572 7.60953 10.8333 7.33338 10.8333H6.50005ZM10.167 4.66666C10.167 4.39052 10.3909 4.16666 10.667 4.16666H11.5004C11.7765 4.16666 12.0004 4.39052 12.0004 4.66666C12.0004 4.94281 11.7765 5.16666 11.5004 5.16666H10.667C10.3909 5.16666 10.167 4.94281 10.167 4.66666ZM10.667 7.5C10.3909 7.5 10.167 7.72386 10.167 8C10.167 8.27614 10.3909 8.5 10.667 8.5H11.5004C11.7765 8.5 12.0004 8.27614 12.0004 8C12.0004 7.72386 11.7765 7.5 11.5004 7.5H10.667ZM10.167 11.3333C10.167 11.0572 10.3909 10.8333 10.667 10.8333H11.5004C11.7765 10.8333 12.0004 11.0572 12.0004 11.3333C12.0004 11.6095 11.7765 11.8333 11.5004 11.8333H10.667C10.3909 11.8333 10.167 11.6095 10.167 11.3333Z"
            fill="black"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1852 2.51849C4.35711 2.34658 4.59027 2.25 4.83338 2.25H13.1667C13.4098 2.25 13.643 2.34658 13.8149 2.51849C13.9868 2.69039 14.0834 2.92355 14.0834 3.16667V15.75H3.91672V3.16667C3.91672 2.92355 4.01329 2.69039 4.1852 2.51849ZM2.41672 15.75H1.5C1.08579 15.75 0.75 16.0858 0.75 16.5C0.75 16.9142 1.08579 17.25 1.5 17.25H16.5C16.9142 17.25 17.25 16.9142 17.25 16.5C17.25 16.0858 16.9142 15.75 16.5 15.75H15.5834V3.16667C15.5834 2.52573 15.3288 1.91104 14.8756 1.45783C14.4223 1.00461 13.8077 0.75 13.1667 0.75H4.83338C4.19244 0.75 3.57776 1.00461 3.12454 1.45783C2.67133 1.91104 2.41672 2.52573 2.41672 3.16667V15.75ZM6.5 4.91656C6.08579 4.91656 5.75 5.25235 5.75 5.66656C5.75 6.08078 6.08579 6.41656 6.5 6.41656H7.33333C7.74755 6.41656 8.08333 6.08078 8.08333 5.66656C8.08333 5.25235 7.74755 4.91656 7.33333 4.91656H6.5ZM5.75 9C5.75 8.58579 6.08579 8.25 6.5 8.25H7.33333C7.74755 8.25 8.08333 8.58579 8.08333 9C8.08333 9.41421 7.74755 9.75 7.33333 9.75H6.5C6.08579 9.75 5.75 9.41421 5.75 9ZM6.5 11.5834C6.08579 11.5834 5.75 11.9192 5.75 12.3334C5.75 12.7476 6.08579 13.0834 6.5 13.0834H7.33333C7.74755 13.0834 8.08333 12.7476 8.08333 12.3334C8.08333 11.9192 7.74755 11.5834 7.33333 11.5834H6.5ZM9.91672 5.66656C9.91672 5.25235 10.2525 4.91656 10.6667 4.91656H11.5C11.9143 4.91656 12.25 5.25235 12.25 5.66656C12.25 6.08078 11.9143 6.41656 11.5 6.41656H10.6667C10.2525 6.41656 9.91672 6.08078 9.91672 5.66656ZM10.6667 8.25C10.2525 8.25 9.91672 8.58579 9.91672 9C9.91672 9.41421 10.2525 9.75 10.6667 9.75H11.5C11.9143 9.75 12.25 9.41421 12.25 9C12.25 8.58579 11.9143 8.25 11.5 8.25H10.6667ZM9.91672 12.3334C9.91672 11.9192 10.2525 11.5834 10.6667 11.5834H11.5C11.9143 11.5834 12.25 11.9192 12.25 12.3334C12.25 12.7476 11.9143 13.0834 11.5 13.0834H10.6667C10.2525 13.0834 9.91672 12.7476 9.91672 12.3334Z"
            fill="black"
          />
        </>
      )}
    </svg>
  )
}

export default LocationIcon
