import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const DashboardIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 13, 14',
}: SvgIconType) => {
  return (
    <svg width="13" height="14" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {(
          <>
            <path
                d="M11 0.783203H5.48H3.98H2C0.9 0.783203 0 1.6832 0 2.7832V11.7832C0 12.8832 0.9 13.7832 2 13.7832H11C12.1 13.7832 13 12.8832 13 11.7832V2.7832C13 1.6832 12.1 0.783203 11 0.783203ZM11 2.2832C11.27 2.2832 11.5 2.5132 11.5 2.7832V8.2632H5.48V2.2832H11ZM1.5 11.7832V2.7832C1.5 2.5132 1.73 2.2832 2 2.2832H3.98V12.2832H2C1.73 12.2832 1.5 12.0532 1.5 11.7832ZM11 12.2832H5.48V9.7632H11.5V11.7832C11.5 12.0532 11.27 12.2832 11 12.2832Z"
                fill="#001238"/>
          </>
      )}
    </svg>
  )
}

export default DashboardIcon
