import { createMuiTheme } from '@material-ui/core'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6BB870',
    },
    background: {
      default: '#FFF',
    },
    type: 'light',
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
    fontSize: 14,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#1B1C1D',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        borderRadius: 12,
        textTransform: 'unset',
      },
      contained: {
        backgroundColor: '#f0f0f0',
        color: '#7F8385',
        borderRadius: 6,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        color: '#fff',
        '&:hover': {
          backgroundColor: '#4D8D51',
        },
        '&:disabled': {
          color: '#B9BBBD',
          backgroundColor: 'rgba(185, 187, 189, 0.14)',
        },
      },
      text: {
        color: '#7F8385',
        '&:hover': {
          color: '#474A4B',
          textDecoration: 'none',
        },
        '&:disabled': {
          color: '#B9BBBD',
        },
      },
      textPrimary: {
        '&:hover': {
          color: '#4D8D51',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid #B9BBBD',
        },
        '&.Mui-error': {
          '&:after': {
            borderBottom: '1px solid #F37066',
          },
        },
        '&:before': {
          borderBottom: '1px solid #DBDDDF',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `1px solid #B9BBBD`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid #B9BBBD`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: '#F37066',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
})

export default theme
