import { AnyAction } from 'redux'
import {
  TurnDownAllocationsType,
  DragType,
  DragHistoryType,
} from '@/redux/turndownAllocations/types'
import {
  allocateNormalize,
  basketNormalize,
  initAllocatedNormalize,
} from '@/redux/turndownAllocations/service'
import actions from './actions'

export interface TurnDownAllocationsStateType extends TurnDownAllocationsType {
  filters: {
    floor: any
    status: any
    reservation_status: any
  }
  loading: boolean
  history: DragHistoryType
  updating: boolean
}

const initialState: TurnDownAllocationsStateType = {
  rooms: {},
  totalFloors: 0,
  turndownAttendants: [],
  turndownAllocated: {},
  filters: {
    floor: '',
    status: '',
    reservation_status: '',
  },
  history: {},
  loading: false,
  updating: false,
}

const addToHistory = (
  state: TurnDownAllocationsStateType,
  { id, destination, source }: DragType,
): DragHistoryType => {
  const room = state.history[id]

  if (room) {
    if (room.id === destination.id) {
      const clone = { ...state.history }
      delete clone[id]
      return { ...clone }
    }
    return state.history
  }

  return {
    ...state.history,
    [id]: source,
  }
}

const dragElement = (
  state: TurnDownAllocationsStateType,
  { id, destination, source }: DragType,
): TurnDownAllocationsStateType => {
  let newDestination = {}
  let newSource = {}
  if (
    destination.id &&
    destination.index !== undefined &&
    destination.id in state.turndownAllocated
  ) {
    const clone = [...state.turndownAllocated[destination.id]]

    if (destination.id === source.id && source.index !== undefined) {
      clone.splice(source.index, 1)
    }
    clone.splice(destination?.index, 0, id)
    newDestination = { [destination.id]: clone }
  }

  if (source.id && source.id in state.turndownAllocated && destination.id !== source.id) {
    newSource = {
      [source.id]: state.turndownAllocated[source.id].filter((item: any) => item !== id),
    }
  }

  const history = addToHistory(state, { id, destination, source })
  return {
    ...state,
    turndownAllocated: { ...state.turndownAllocated, ...newDestination, ...newSource },
    history,
  }
}

const clearAllocated = (state: TurnDownAllocationsStateType) => {
  const { basket, ...rest } = state.turndownAllocated

  const initAllocated = initAllocatedNormalize(state.rooms)
  const turndownAllocated = allocateNormalize(state.turndownAttendants, initAllocated)

  const history = Object.entries(turndownAllocated).reduce<DragHistoryType>(
    (acc, [key, values]: [string, string[]]) => {
      return {
        ...acc,
        ...values.reduce<DragHistoryType>(
          (carry, item: string, index: number) => ({ ...carry, [item]: { id: key, index } }),
          {},
        ),
      }
    },
    {},
  )

  return {
    ...state,
    turndownAllocated: {
      ...Object.entries(rest).reduce((acc, [key]) => ({ ...acc, [key]: [] }), {}),
      basket: Object.values(state.rooms).reduce((acc: string[], room) => [...acc, room.id], []),
    },
    history,
  }
}

const revertChanges = (state: TurnDownAllocationsStateType) => {
  const initAllocated = initAllocatedNormalize(state.rooms)
  const turndownAllocated = allocateNormalize(state.turndownAttendants, initAllocated)
  return {
    ...state,
    turndownAllocated: {
      ...turndownAllocated,
      basket: basketNormalize(state.rooms),
    },
    history: {},
  }
}

const updateState = (state: TurnDownAllocationsStateType, data: any) => {
  const [clone] = Object.values(state.rooms).filter((item) => data?.roomID.includes(item.id))
  let newRoomStatus = {}
  if (data?.newStatus) {
    newRoomStatus = {
      status: data?.newStatus,
    }
  }

  if (data?.reservation_status) {
    newRoomStatus = {
      status: data?.newStatus,
      reservation_status: data?.reservation_status,
      linenStatus: undefined,
    }
  }
  const roomState = { ...clone, ...newRoomStatus }

  return {
    ...state,
    rooms: {
      ...state.rooms,
      [data?.roomID]: { ...roomState },
    },
  }
}

export default function reducer(
  state: TurnDownAllocationsStateType = initialState,
  action: AnyAction,
): TurnDownAllocationsStateType {
  switch (action.type) {
    case actions.SET_STATE_TURNDOWN:
      return { ...state, ...action.payload }
    case actions.SET_FILTERS_TURNDOWN:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case actions.DRAG_ELEMENT_TURNDOWN:
      return dragElement(state, action.payload)
    case actions.CLEAR_ALLOCATED_TURNDOWN:
      return clearAllocated(state)
    case actions.REVERT_CHANGES_TURNDOWN:
      return revertChanges(state)
    case actions.UPDATE_STATE_TURNDOWN:
      return updateState(state, action.payload)
    case actions.SET_AUTO_ALLOCATE_TURNDOWN:
      return {
        ...state,
        turndownAllocated: { ...state.turndownAllocated, ...action.payload.turndownAllocated },
        history: action.payload.history,
      }
    case actions.CLEAR_ALL_TURNDOWN:
      return { ...state, ...initialState }
    default:
      return state
  }
}
