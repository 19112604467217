import * as action_types from './constants'

export interface AdminDeliveryStateType {
  deliveryServiceListing: []
  deliverySingleData: {
    id: string
    name: string
    image: string
    email: string
    phone: string
    role: string
  }
  deliveryServiceCategory: { [key: string]: string }
  locations: { label: string; value: string }[]
}

const initialState = {
  deliveryServiceListing: [],
  deliveryServiceCategory: '',
  deliverySingleData: {
    id: '',
    name: '',
    image: '',
    email: '',
    phone: '',
    role: '',
  },
  locations: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.DELIVERY_SERVICE_LISTING:
      return { ...state, deliveryServiceListing: data }

    case action_types.DELIVERY_CATEGORY:
      return { ...state, deliveryServiceCategory: data }

    case action_types.DELIVERY_SINGLE:
      return { ...state, deliverySingleData: data }

    case action_types.DELIVERY_LOCATIONS:
      return { ...state, locations: data }

    case action_types.CLEAR_DATA:
      return { ...state, deliverySingleData: {} }

    default:
      return state
  }
}
