import { AnyAction } from 'redux'
import actions from './actions'
import { ShiftType } from './types'

export interface ShiftStateType {
  shifts: ShiftType[]
  loading: boolean
}

const initialState: ShiftStateType = {
  shifts: [],
  loading: false,
}

export default function reducer(
  state: ShiftStateType = initialState,
  action: AnyAction,
): ShiftStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
