import Icon from '@ant-design/icons'

const PlusSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.775 10.6504C17.9281 10.6504 18.0484 10.7051 18.1359 10.8145C18.2453 10.902 18.3 11.0223 18.3 11.1754V12.2254C18.3 12.3785 18.2453 12.5098 18.1359 12.6191C18.0484 12.7066 17.9281 12.7504 17.775 12.7504H13.05V17.4754C13.05 17.6285 12.9953 17.7598 12.8859 17.8691C12.7984 17.9566 12.6781 18.0004 12.525 18.0004H11.475C11.3218 18.0004 11.1906 17.9566 11.0812 17.8691C10.9937 17.7598 10.95 17.6285 10.95 17.4754V12.7504H6.22495C6.07183 12.7504 5.94058 12.7066 5.8312 12.6191C5.7437 12.5098 5.69995 12.3785 5.69995 12.2254V11.1754C5.69995 11.0223 5.7437 10.902 5.8312 10.8145C5.94058 10.7051 6.07183 10.6504 6.22495 10.6504H10.95V5.92539C10.95 5.77227 10.9937 5.65195 11.0812 5.56445C11.1906 5.45508 11.3218 5.40039 11.475 5.40039H12.525C12.6781 5.40039 12.7984 5.45508 12.8859 5.56445C12.9953 5.65195 13.05 5.77227 13.05 5.92539V10.6504H17.775Z"
            fill="#001238"/>
    </svg>
)

const PlusIcon = (props: any) => <Icon component={PlusSvg} {...props} />

export default PlusIcon
