import { Action, Dispatch } from 'redux'
import axios from '@/config/axiosInterceptor'
import { AxiosResponse } from 'axios'

export const createRequest = (payload: any) => {
  return axios.post(`updateItems`, payload)
}

export const deleteRequest = (id: string) => {
  return axios.delete(`deleteItem/${id}/true`)
}

export const createCategory = (payload: any) => {
  return axios.post(`updateRequestCategory`, payload)
}

export const deleteCategory = (id: string) => {
  return axios.delete(`deleteRequestCategory/${id}/true`)
}

export const updateTaskStatus = (id: string, data: any) => {
  return axios.patch(`updateStatus/${id}`, data)
}

export const updateRequestCategoryIndex = (payload: any) => {
  return axios.post(`updateRequestCategoryIndex`, payload)
}

export const createDeliverySchedule = (payload: any) => {
  return axios.post(`createDeliverySchedule`, payload)
}

export const updatePermission = ({
  id,
  access_level,
  is_checked,
}: {
  id: string
  access_level: number
  is_checked: boolean
}): Promise<AxiosResponse<void>> => {
  return axios.put(
    `updateRolePermission/${id}`,
    JSON.stringify({ is_checked, access_level }),
  )
}

export const deActiveLocation = (hotelID: string, type: string) => {
  return axios.put(`hotels/${hotelID}/${type}`)
}

export const sync = async(propertyId: string) => {
  await axios.get(`cloudbeds-sync/hotels/${propertyId}`)
  await axios.get(`cloudbeds-sync/rooms/${propertyId}`)
  const syncHouseKeeping = axios.get(`cloudbeds-sync/houseKeeping/${propertyId}`)
  const syncReservations = axios.get(`cloudbeds-sync/reservations/${propertyId}`)
  await Promise.all([syncHouseKeeping, syncReservations])
  await axios.get(`cloudbeds-sync/reservation-notes/${propertyId}`)
  await axios.get(`cloudbeds-sync/hotels/${propertyId}/all`)
}

export const doFullSync = async() => {
  const userInfo = (await axios.get(`getUsersInfo`)).data
  const locations = userInfo.available_locations
  const syncPromises = locations.map(async (location: { value: any }) => {
    const hotel = await axios.get(`hotels/${location.value}`)
    await sync(hotel.data.cloudbeds_property_id)
  })
  await Promise.all(syncPromises)
}
