import Icon from '@ant-design/icons'

const CloseSvg = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8333 12.1666L12.1667 23.8333M23.8333 23.8333L12.1667 12.1666"
      stroke="#16181D"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
)

const CloseIcon = (props: any) => <Icon component={CloseSvg} {...props} />

export default CloseIcon
