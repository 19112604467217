import Icon from '@ant-design/icons'

const LeftArrowSvg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 13L16 24L27 35"
      stroke="#1B1C1D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const LeftArrowIconMedium = (props: any) => <Icon component={LeftArrowSvg} {...props} />

export default LeftArrowIconMedium
