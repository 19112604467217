import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'antd/dist/antd.css'
import Router from '@/router'
import theme from '@/config/theme'
import 'dotenv/config'

const App: React.FunctionComponent = () => {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer className="custom-toast" autoClose={5000} />
      <Router />
    </ThemeProvider>
  )
}

export default App
