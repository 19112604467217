import { AnyAction } from 'redux'
import { updateRoomNote, deleteRoomNote, addRoomNote } from './service'
import { NotesDocType } from './types'
import actions from './actions'

export interface NotesStateType {
  notes: NotesDocType[]
  limit: number
  hasNext: boolean
  lastId: string | null
  loading: boolean
}

const initialState: NotesStateType = {
  notes: [],
  limit: 20,
  hasNext: false,
  lastId: null,
  loading: false,
}

export default function reducers(
  state: NotesStateType = initialState,
  action: AnyAction,
): NotesStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ROOM_NOTES:
      return {
        ...state,
        notes: [...action.payload.notes, ...state.notes],
        hasNext: action.payload.hasNext,
        lastId: action.payload.lastId,
      }
    case actions.EDIT_ROOM_NOTE_SUCCESS:
      return { ...state, notes: updateRoomNote([...state.notes], action.notes) }
    case actions.ADD_ROOM_NOTE:
      return { ...state, notes: addRoomNote([...state.notes], action.notes) }
    case actions.DELETE_ROOM_NOTE_SUCCESS: {
      const { notes } = state
      return {
        ...state,
        ...deleteRoomNote([...notes], action.note),
      }
    }
    case actions.CLEAR_ALL: {
      return initialState
    }
    default:
      return state
  }
}
