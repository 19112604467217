import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { composeWithDevTools } from '@redux-devtools/extension'

import reducers, { IRootState } from './reducers'
import sagas from './sagas'

const saga = createSagaMiddleware()

// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
//   }
// }

const middleWares = [saga, thunk]

const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(...middleWares)))
saga.run(sagas)

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
export default store
