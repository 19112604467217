import { AnyAction } from 'redux'
import { AllocationsType, DragType, DragHistoryType } from '@/redux/allocations/types'
import {
  allocateNormalize,
  basketNormalize,
  initAllocatedNormalize,
} from '@/redux/allocations/service'
import actions from './actions'

export interface AllocationsStateType extends AllocationsType {
  filters: {
    floor: any
    status: any
    reservation_status: any
  }
  loading: boolean
  history: DragHistoryType
  updating: boolean
}

export interface TasksStateType extends AllocationsType {
  filters: {
    floor: any
    status: any
  }
  loading: boolean
  history: DragHistoryType
  updating: boolean
}

const initialState: AllocationsStateType = {
  rooms: {},
  totalFloors: 0,
  attendants: [],
  turndownAttendants: [],
  allocated: {},
  turndownAllocated: {},
  filters: {
    floor: '',
    status: '',
    reservation_status: '',
  },
  history: {},
  loading: false,
  updating: false,
}

const addToHistory = (
  state: AllocationsStateType,
  { id, destination, source }: DragType,
): DragHistoryType => {
  const room = state.history[id]

  if (room) {
    if (room.id === destination.id) {
      const clone = { ...state.history }
      delete clone[id]
      return { ...clone }
    }
    return state.history
  }

  return {
    ...state.history,
    [id]: source,
  }
}

const dragElement = (
  state: AllocationsStateType,
  { id, destination, source }: DragType,
): AllocationsStateType => {
  let newDestination = {}
  let newSource = {}
  if (destination.id && destination.index !== undefined && destination.id in state.allocated) {
    const clone = [...state.allocated[destination.id]]

    if (destination.id === source.id && source.index !== undefined) {
      clone.splice(source.index, 1)
    }
    clone.splice(destination?.index, 0, id)
    newDestination = { [destination.id]: clone }
  }

  if (source.id && source.id in state.allocated && destination.id !== source.id) {
    newSource = { [source.id]: state.allocated[source.id].filter((item: any) => item !== id) }
  }

  const history = addToHistory(state, { id, destination, source })
  return {
    ...state,
    allocated: { ...state.allocated, ...newDestination, ...newSource },
    history,
  }
}

const clearAllocated = (state: AllocationsStateType) => {
  const { basket, ...rest } = state.allocated

  const initAllocated = initAllocatedNormalize(state.rooms)
  const allocated = allocateNormalize(state.attendants, initAllocated)

  const history = Object.entries(allocated).reduce<DragHistoryType>(
    (acc, [key, values]: [string, string[]]) => {
      return {
        ...acc,
        ...values.reduce<DragHistoryType>(
          (carry, item: string, index: number) => ({ ...carry, [item]: { id: key, index } }),
          {},
        ),
      }
    },
    {},
  )

  return {
    ...state,
    allocated: {
      ...Object.entries(rest).reduce((acc, [key]) => ({ ...acc, [key]: [] }), {}),
      basket: Object.values(state.rooms).reduce((acc: string[], room) => [...acc, room.id], []),
    },
    history,
  }
}

const revertChanges = (state: AllocationsStateType) => {
  const initAllocated = initAllocatedNormalize(state.rooms)
  const allocated = allocateNormalize(state.attendants, initAllocated)
  return {
    ...state,
    allocated: {
      ...allocated,
      basket: basketNormalize(state.rooms),
    },
    history: {},
  }
}

const updateState = (state: AllocationsStateType, data: any) => {
  const [clone] = Object.values(state.rooms).filter((item) => data?.roomID.includes(item.id))
  const newRoomStatus: any = {}
  if (data?.newStatus) {
    newRoomStatus.status = data?.newStatus
  }

  if (data?.newTurnDownStatus) {
    newRoomStatus.turndown_status = data?.newTurnDownStatus
  }

  if (data?.linenStatus) {
    newRoomStatus.linen_status = data?.linenStatus ?? undefined
  }

  if (data?.reservation_status) {
    newRoomStatus.reservation_status = data?.reservation_status
  }

  if (data?.cleaningTime) {
    newRoomStatus.cleaning_time = data?.cleaningTime
  }

  if (data?.turndownTime) {
    newRoomStatus.turndown_time = data?.turndownTime
  }

  const roomState = { ...clone, ...newRoomStatus }
  return {
    ...state,
    rooms: {
      ...state.rooms,
      [data?.roomID]: { ...roomState },
    },
  }
}

export default function reducer(
  state: AllocationsStateType = initialState,
  action: AnyAction,
): AllocationsStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case actions.DRAG_ELEMENT:
      return dragElement(state, action.payload)
    case actions.CLEAR_ALLOCATED:
      return clearAllocated(state)
    case actions.REVERT_CHANGES:
      return revertChanges(state)
    case actions.UPDATE_STATE:
      return updateState(state, action.payload)
    case actions.SET_AUTO_ALLOCATE:
      return {
        ...state,
        allocated: { ...state.allocated, ...action.payload.allocated },
        history: action.payload.history,
      }
    case actions.CLEAR_ALL:
      return { ...state, ...initialState }
    default:
      return state
  }
}
