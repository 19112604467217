import { AnyAction } from 'redux'
import { NotesDocType } from '@/redux/notes/types'
import { ActiveRoomType } from './types'
import { pinNote, unpinNote, updateRoom } from './service'
import actions from './actions'

export interface RoomStateType {
  room: ActiveRoomType | null
  loading: boolean
  pinned_note: NotesDocType | null
}

const initialState: RoomStateType = {
  room: null,
  pinned_note: null,
  loading: false,
}

export default function reducer(
  state: RoomStateType = initialState,
  action: AnyAction,
): RoomStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SAVE_ONE:
      return { ...state, room: action.payload.room }
    case actions.CLEAR_DATA:
      return { ...state, room: null }
    case actions.PIN_NOTE:
      return { ...state, pinned_note: pinNote(action.notes) }
    case actions.UNPIN_NOTE:
      return { ...state, pinned_note: unpinNote(action.notes, state.pinned_note) }
    case actions.UPDATE_ACTIVE_ROOM_BY_SOCKET: {
      const room = state.room ? updateRoom(action.rooms, state.room) : null
      return { ...state, room }
    }
    case actions.CHANGE_ACTIVE_ROOM_STATUS: {
      const room = state.room ? { ...state.room, status: action.status } : null
      return { ...state, room }
    }
    default:
      return state
  }
}
