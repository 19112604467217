import { AnyAction } from 'redux'
import actions from './actions'
import { UserType } from './types'
import moment from 'moment-timezone'

export interface IUserState {
  loading: boolean
  user: UserType | null
}

const initialState: IUserState = {
  loading: false,
  user: null,
}

const reducer = (state: IUserState = initialState, action: AnyAction): IUserState | null => {
  switch (action.type) {
    case actions.MAP_USER_DATA:
      return {
        ...state,
        user: { ...state.user, ...action.data },
      }
    case actions.SET_USER_INFO:
      const timezone = action.data?.hotel?.timezone_area
      if (timezone) {
        moment.tz.setDefault(timezone)
      }
      return {
        ...state,
        user: action.data,
      }
    default:
      return state
  }
}

export default reducer
