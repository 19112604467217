import { toast } from 'react-toastify'
import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
  race,
  spawn,
  takeLatest,
} from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import messages from '@/config/messages'
import {
  getAutoAllocationService,
  getRoomsWithAttendants as getRoomsWithAttendantsService,
  saveChanges as saveChangesService,
} from '@/services/turndownAllocations'
import { RoomsResponseType, RoomType } from '@/redux/rooms/types'
import { fetchWorkShiftsList } from '@/services/shifts'
import { ShiftType, ShiftUserType } from '@/redux/shifts/types'
import { formatShiftTime } from '@/utils/dates'
import {
  initAllocatedNormalize,
  allocateNormalize,
  basketNormalize,
} from '@/redux/turndownAllocations/service'
import { IRootState } from '@/redux/reducers'
import { getInitAllocatedState } from '@/redux/turndownAllocations/selectors'
import { DragHistoryType } from '@/redux/turndownAllocations/types'
import { AnyAction } from 'redux'
import { IS_DEV } from '@/config/constants'
import actions, {
  updating,
  loading,
  setRoomsWithAttendantsSuccess,
  setRoomsAttendantsSuccess,
  setAutoAllocate,
  saveSkipped,
} from './actions'
// import eventChannel from './websocket'

export function* getRoomsWithAttendants(data: any): SagaIterator {
  yield put(loading(true))
  try {
    const [allocationsData, shifts]: [RoomsResponseType, ShiftType[]] = yield all([
      call(
        getRoomsWithAttendantsService,
        data?.payload?.hotelID,
        data?.payload?.date,
        data?.payload?.type,
      ),
      call(fetchWorkShiftsList, data?.payload?.hotelID, data?.payload?.date, data?.payload?.type),
    ])

    const turndownAttendants = shifts.reduce((prev: ShiftUserType[], current: ShiftType) => {
      const slot = formatShiftTime(current.start, current.end)
      return [
        ...prev,
        {
          ...current.user,
          slot,
          workshift_id: current.workshift_id,
          display_role: current.display_role,
        },
      ]
    }, [])

    const initAllocated = initAllocatedNormalize(allocationsData.rooms)
    const turndownAllocated = allocateNormalize(turndownAttendants, initAllocated)
    turndownAllocated.basket = basketNormalize(allocationsData.rooms)

    yield put(
      setRoomsWithAttendantsSuccess({ ...allocationsData, turndownAllocated, turndownAttendants }),
    )
  } catch (e: any) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getAutoAllocate(payload: any): SagaIterator {
  yield put(updating(true))
  try {
    const allocationsData: { [k in string]: string[] }[] = yield call(
      getAutoAllocationService,
      payload?.payload?.hotelID,
      payload?.payload?.type,
    )

    const stateAllocated: { [k in string]: string[] } = yield select(
      (state: IRootState) => state.turndownAllocations.turndownAllocated,
    )

    const initAllocatedState: Record<string, string[]> = yield select(getInitAllocatedState)

    const turndownAttendants: ShiftUserType[] = yield select(
      (state: IRootState) => state.turndownAllocations.turndownAttendants,
    )

    const data = allocationsData.reduce(
      (acc: { [x: string]: string[] }, item: { [x: string]: string[] }) => ({
        ...acc,
        ...item,
      }),
      {},
    )

    const turndownAllocated = allocateNormalize(turndownAttendants, data)

    const allocatedMap = Object.values(data).flat()

    turndownAllocated.basket = Object.values(stateAllocated)
      .flat()
      .filter((item: string) => !allocatedMap.includes(item))

    const history = Object.entries(initAllocatedState).reduce<DragHistoryType>(
      (acc, [key, values]: [string, string[]]) => {
        return {
          ...acc,
          ...values.reduce<DragHistoryType>((carry, item, index) => {
            if (turndownAllocated?.[key] && turndownAllocated?.[key].includes(item)) {
              return carry
            }

            return { ...carry, [item]: { id: key, index } }
          }, {}),
        }
      },
      {},
    )
    yield put(setAutoAllocate({ turndownAllocated, history }))
  } catch (e: any) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

export function* saveChanges({ data }: AnyAction): SagaIterator {
  yield put(updating(true))

  const { basket, ...rest } = yield select(
    (state: IRootState) => state.turndownAllocations.turndownAllocated,
  )

  const body = {
    hotelId: data?.HotelID,
    allocation: Object.entries(rest || {}).reduce<
      { workshift: string; turndown_rooms: string[] }[]
    >((acc, [key, item]) => [...acc, { workshift: key, turndown_rooms: item as string[] }], []),
  }

  try {
    yield call(saveChangesService, data.statusType, body)
    const allocationsData = yield call(
      getRoomsWithAttendantsService,
      data?.HotelID,
      data?.date,
      data?.statusType,
    )
    yield put(setRoomsAttendantsSuccess(allocationsData))
    data?.afterSubmit?.()
  } catch (e: any) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

export function* skipAndSend(): SagaIterator {
  yield put(updating(true))
  try {
    const stateHistory: DragHistoryType = yield select(
      (state: IRootState) => state.turndownAllocations.history,
    )

    const stateRooms: { [k: string]: RoomType } = yield select(
      (state: IRootState) => state.turndownAllocations.rooms,
    )

    const stateAllocated: { [k in string]: string[] } = yield select(
      (state: IRootState) => state.turndownAllocations.turndownAllocated,
    )

    const cleaningRooms = Object.values(stateRooms)
      .filter((item) => stateHistory?.[item.id] && item.is_cleaning)
      .reduce<string[]>((acc, item) => [...acc, item.id], [])

    const turndownAllocated: Record<string, string[]> = Object.entries(stateAllocated).reduce(
      (acc, [key, values]) => ({
        ...acc,
        [key]: values.filter((item) => !cleaningRooms.includes(item)),
      }),

      {},
    )

    cleaningRooms.forEach((key) => {
      const item = stateHistory[key]
      if (item?.id && item?.index !== undefined && turndownAllocated?.[item.id]) {
        if (turndownAllocated[item.id].length > item.index) {
          const clone = [...turndownAllocated[item.id]]
          clone.splice(item.index, 0, key)
          turndownAllocated[item.id] = clone
        } else {
          turndownAllocated[item.id].push(key)
        }
      }
    })

    yield put(saveSkipped(turndownAllocated))
    yield call(saveChanges, { type: actions.SAVE_CHANGES_TURNDOWN })
  } catch (e: any) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(updating(false))
  }
}

// function* listenRoomsSaga({ id }: AnyAction): SagaIterator {
//   const roomChannel = yield call(eventChannel, id)
//   try {
//     while (true) {
//       const action = yield take(roomChannel)
//       yield put(action)
//     }
//   } catch (e) {
//     if (IS_DEV) {
//       // eslint-disable-next-line no-console
//       console.log('listenRoomsSagaError', {
//         message: (e as Error).message,
//       })
//     }
//     toast.error(messages.socketError)
//   } finally {
//     roomChannel?.close?.()
//   }
// }

function* websocketChannel(): SagaIterator {
  while (true) {
    const action = yield take(actions.START_ALLOCATION_SOCKET_CHANNEL_TURNDOWN)
    try {
      yield race({
        // task: call(listenRoomsSaga, action),
        cancelTask: take(actions.CLOSE_ALLOCATION_SOCKET_CHANNEL_TURNDOWN),
      })
    } catch (e) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('watchRoomsSagaError', {
          message: (e as Error).message,
        })
      }
      toast.error(messages.socketError)
    }
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(websocketChannel)
  yield all([
    takeEvery(actions.GET_ROOMS_WITH_ATTENDANTS_REQUEST_TURNDOWN, getRoomsWithAttendants),
    takeEvery(actions.GET_AUTO_ALLOCATE_TURNDOWN, getAutoAllocate),
    takeEvery(actions.SKIP_AND_SEND_TURNDOWN, skipAndSend),
    takeLatest(actions.SAVE_CHANGES_TURNDOWN, saveChanges),
  ])
}
