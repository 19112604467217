import Icon from '@ant-design/icons'

const FileNowSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.88326 4C1.88327 2.50884 3.09209 1.30002 4.58326 1.30002H13.5835C15.0746 1.30002 16.2835 2.50885 16.2835 4.00002V9.50002C16.2835 9.88662 15.9701 10.2 15.5835 10.2C15.1969 10.2 14.8835 9.88662 14.8835 9.50002V4.00002C14.8835 3.28205 14.3014 2.70002 13.5835 2.70002H4.58326C3.86529 2.70002 3.28326 3.28204 3.28326 4.00001L3.28318 16C3.28317 16.7179 3.8652 17.3 4.58317 17.3H8.58318C8.96978 17.3 9.28318 17.6134 9.28318 18C9.28318 18.3866 8.96978 18.7 8.58318 18.7H4.58317C3.092 18.7 1.88317 17.4911 1.88318 16L1.88326 4ZM5.38349 6.00002C5.38349 5.61342 5.69689 5.30002 6.08349 5.30002H12.0835C12.4701 5.30002 12.7835 5.61342 12.7835 6.00002C12.7835 6.38662 12.4701 6.70002 12.0835 6.70002H6.08349C5.69689 6.70002 5.38349 6.38662 5.38349 6.00002ZM5.38349 9.00002C5.38349 8.61342 5.69689 8.30002 6.08349 8.30002H12.0835C12.4701 8.30002 12.7835 8.61342 12.7835 9.00002C12.7835 9.38662 12.4701 9.70002 12.0835 9.70002H6.08349C5.69689 9.70002 5.38349 9.38662 5.38349 9.00002ZM5.38349 12C5.38349 11.6134 5.69689 11.3 6.08349 11.3H9.08349C9.47009 11.3 9.78349 11.6134 9.78349 12C9.78349 12.3866 9.47009 12.7 9.08349 12.7H6.08349C5.69689 12.7 5.38349 12.3866 5.38349 12ZM17.9118 12.5049C18.1852 12.7782 18.1852 13.2214 17.9118 13.4948L13.9118 17.495C13.7805 17.6263 13.6025 17.7 13.4168 17.7C13.2312 17.7 13.0531 17.6263 12.9218 17.495L11.0885 15.6617C10.8151 15.3883 10.8151 14.9451 11.0885 14.6717C11.3619 14.3983 11.8051 14.3983 12.0785 14.6717L13.4168 16.0101L16.9218 12.5049C17.1952 12.2315 17.6384 12.2315 17.9118 12.5049Z"
      fill="#16181D"
    />
  </svg>
)

const FileNowIcon = (props: any) => <Icon component={FileNowSvg} {...props} />

export default FileNowIcon
