import { AnyAction } from 'redux'
import { Hotel, HotelGroup } from '@/redux/hotels/types'
import { IAddHotelForm } from '@/views/AddHotel/types'
import type { History } from 'history'
import { IEditGeneralHotelForm } from '@/views/EditHotel/components/GeneralTab/types'
import { IEditFloorsHotelForm } from '@/views/EditHotel/components/FloorsTab/types'
import { IEditHotelRoomTypeTabForm } from '@/views/EditHotel/components/RoomTypeTab/types'
import { IEditHotelRoomTypeTabForm2 } from '@/views/EditHotel/components/RoomServiceTab/types'
import { Pagination } from '../types'

const actions = {
  SET_STATE: 'hotel/SET_STATE',
  GET_ONE: 'hotel/GET_ONE',
  GET_ONE_BY_ID: 'hotel/GET_ONE_BY_ID',
  GET_HOTEL_FLOORS_WITH_ROOMS: 'hotel/GET_HOTEL_FLOORS_WITH_ROOMS',
  GET_FLOORS_BY_ID: 'hotel/GET_FLOORS_BY_ID',
  UPDATE_CSV: 'hotel/UPDATE_CSV',
  GET_HOTELS_REQUEST: 'hotel/GET_HOTELS_REQUEST',
  GET_HOTELS_SUCCESS: 'hotel/GET_HOTELS_SUCCESS',
  CLEAR_HOTELS: 'hotel/CLEAR_HOTELS',
  GET_GROUPS_REQUEST: 'hotel/GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'hotel/GET_GROUPS_SUCCESS',
  ADD_NEW_HOTEL: 'hotel/ADD_NEW_HOTEL',
  EDIT_GENERAL_HOTEL: 'hotel/EDIT_GENERAL_HOTEL',
  EDIT_HOTEL_FLOORS: 'hotel/EDIT_HOTEL_FLOORS',
  EDIT_HOTEL_ROOMS_TYPE: 'hotel/EDIT_HOTEL_ROOMS_TYPE',
  DELETE_HOTEL: 'hotel/DELETE_HOTEL',
  SET_ERROR: 'hotel/SET_ERROR',
} as const

export interface IGetHotelsRequest {
  limit: number
  page: number
  search?: string
  shortFormat?: boolean
}
export interface IGetHotelsResponse {
  hotels: Hotel[]
  pagination: Pagination
  roles: { label: string; value: string }[]
}

export interface IGetHotelGroupsResponse {
  groups: HotelGroup[]
}

export interface IGetHotelParsersResponse {
  parsers: string[]
}

export interface IAddNewHotelRequest {
  data: IAddHotelForm
  history: History
}

export const getHotels = (payload: IGetHotelsRequest): AnyAction => {
  return {
    type: actions.GET_HOTELS_REQUEST,
    payload,
  }
}

export const getHotelsSuccess = (data: IGetHotelsResponse): AnyAction => {
  return {
    type: actions.GET_HOTELS_SUCCESS,
    data,
  }
}

export const clearHotels = (): AnyAction => {
  return {
    type: actions.CLEAR_HOTELS,
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const getOne = (): AnyAction => {
  return {
    type: actions.GET_ONE,
  }
}

export const getOneById = (id: string): AnyAction => {
  return {
    type: actions.GET_ONE_BY_ID,
    id,
  }
}

export const getHotelFloorsWithRooms = (hotelId: string, floor: number): AnyAction => {
  return {
    type: actions.GET_HOTEL_FLOORS_WITH_ROOMS,
    payload: { hotelId, floor },
  }
}

export const getFloorsById = (id: string): AnyAction => {
  return {
    type: actions.GET_FLOORS_BY_ID,
    id,
  }
}

export const updateCsv = (date: string): AnyAction => {
  return {
    type: actions.UPDATE_CSV,
    payload: { date },
  }
}

export const getHotelGroups = (): AnyAction => {
  return {
    type: actions.GET_GROUPS_REQUEST,
  }
}

export const getHotelGroupsSuccess = (payload: IGetHotelGroupsResponse): AnyAction => {
  return {
    type: actions.GET_GROUPS_SUCCESS,
    payload,
  }
}

export const addNewHotel = (data: IAddNewHotelRequest): AnyAction => ({
  type: actions.ADD_NEW_HOTEL,
  data,
})

export const editGeneralHotel = (id: string, data: IEditGeneralHotelForm): AnyAction => ({
  type: actions.EDIT_GENERAL_HOTEL,
  payload: { id, data },
})

export const editHotelFloors = (id: string, data: IEditFloorsHotelForm): AnyAction => ({
  type: actions.EDIT_HOTEL_FLOORS,
  payload: { id, data },
})

export const deleteHotel = (id: string, history: History): AnyAction => ({
  type: actions.DELETE_HOTEL,
  payload: { id, history },
})

export const editHotelRoomsType = (
  hotel_id: string,
  data: IEditHotelRoomTypeTabForm,
  floor_id: number,
): AnyAction => ({
  type: actions.EDIT_HOTEL_ROOMS_TYPE,
  data: { hotel_id, data, floor_id },
})

export const editHotelRoomsType2 = (
  hotel_id: string,
  data: IEditHotelRoomTypeTabForm2,
  floor_id: number,
): AnyAction => ({
  type: actions.EDIT_HOTEL_ROOMS_TYPE,
  data: { hotel_id, data, floor_id },
})

export const setError = (error: Record<string, string | null>): AnyAction => {
  return {
    type: actions.SET_ERROR,
    payload: { error },
  }
}

export default actions
