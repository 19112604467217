import { AnyAction } from 'redux'
import { UserType } from '@/redux/user/types'

const actions = {
  SET_LOADING: 'user/SET_LOADING',
  UPDATE_SELF_REQUEST: 'user/UPDATE_SELF_REQUEST',
  SET_USER_INFO: 'user/SET_USER_INFO',
  UPDATE_SELF_ERROR: 'user/UPDATE_SELF_ERROR',

  MAP_USER_DATA: 'user/MAP_USER_DATA',

  UPDATE_SELF_PASSWORD_REQUEST: 'user/UPDATE_SELF_PASSWORD_REQUEST',
  UPDATE_SELF_PASSWORD_SUCCESS: 'user/UPDATE_SELF_PASSWORD_SUCCES',
  UPDATE_SELF_PASSWORD_ERROR: 'user/UPDATE_SELF_PASSWORD_ERROR',
}

export const loading = (data: boolean): AnyAction => ({
  type: actions.SET_LOADING,
  data,
})

export const updateSelfRequest = (data: FormData): AnyAction => ({
  type: actions.UPDATE_SELF_REQUEST,
  data,
})

export const setUserInfo = (data: UserType | null): AnyAction => ({
  type: actions.SET_USER_INFO,
  data,
})

export const updateSelfPasswordRequest = (password: string): AnyAction => ({
  type: actions.UPDATE_SELF_PASSWORD_REQUEST,
  password,
})

export const mapUserData = (data: { email: string; password: string }): AnyAction => ({
  type: actions.MAP_USER_DATA,
  data,
})

export default actions
