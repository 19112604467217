import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const ProjectsIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 14, 19',
  active,
}: SvgIconType) => {
  return (
    <svg width="14" height="19" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {(
        <>
          <path
              d="M11.6667 3.0332C12.9427 3.0332 14 4.05273 14 5.2832V16.5332C14 17.7988 12.9427 18.7832 11.6667 18.7832H2.33333C1.02083 18.7832 0 17.7988 0 16.5332V5.2832C0 4.05273 1.02083 3.0332 2.33333 3.0332H4.11979C4.41146 1.76758 5.57812 0.783203 7 0.783203C8.38542 0.783203 9.55208 1.76758 9.84375 3.0332H11.6667ZM7 2.4707C6.48958 2.4707 6.125 2.85742 6.125 3.31445C6.125 3.80664 6.48958 4.1582 7 4.1582C7.47396 4.1582 7.875 3.80664 7.875 3.31445C7.875 2.85742 7.47396 2.4707 7 2.4707ZM12.25 16.5332V5.2832C12.25 5.00195 11.9583 4.7207 11.6667 4.7207H10.974C11.0469 4.93164 11.0833 5.10742 11.0833 5.2832V5.8457C11.0833 6.16211 10.7917 6.4082 10.5 6.4082H3.5C3.17188 6.4082 2.91667 6.16211 2.91667 5.8457V5.2832C2.91667 5.10742 2.91667 4.93164 2.98958 4.7207H2.33333C2.00521 4.7207 1.75 5.00195 1.75 5.2832V16.5332C1.75 16.8496 2.00521 17.0957 2.33333 17.0957H11.6667C11.9583 17.0957 12.25 16.8496 12.25 16.5332ZM10.5 9.7832C10.7917 9.7832 11.0833 10.0645 11.0833 10.3457C11.0833 10.6621 10.7917 10.9082 10.5 10.9082H6.41667C6.08854 10.9082 5.83333 10.6621 5.83333 10.3457C5.83333 10.0645 6.08854 9.7832 6.41667 9.7832H10.5ZM10.5 13.1582C10.7917 13.1582 11.0833 13.4395 11.0833 13.7207C11.0833 14.0371 10.7917 14.2832 10.5 14.2832H6.41667C6.08854 14.2832 5.83333 14.0371 5.83333 13.7207C5.83333 13.4395 6.08854 13.1582 6.41667 13.1582H10.5ZM3.79167 12.877C4.26562 12.877 4.66667 13.2637 4.66667 13.7207C4.66667 14.2129 4.26562 14.5645 3.79167 14.5645C3.28125 14.5645 2.91667 14.2129 2.91667 13.7207C2.91667 13.2637 3.28125 12.877 3.79167 12.877ZM3.9375 10.9082C3.75521 10.9082 3.60938 10.873 3.5 10.7676L2.625 9.92383C2.40625 9.71289 2.40625 9.32617 2.625 9.11523C2.84375 8.9043 3.24479 8.9043 3.46354 9.11523L3.9375 9.57227L5.25 8.27148C5.46875 8.06055 5.86979 8.06055 6.08854 8.27148C6.30729 8.48242 6.30729 8.86914 6.08854 9.08008L4.33854 10.7676C4.22917 10.873 4.08333 10.9082 3.9375 10.9082Z"
              fill="#001238"/>
        </>
      )}
    </svg>
  )
}

export default ProjectsIcon
