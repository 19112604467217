import { RoomType } from '@/redux/rooms/types'
import { ShiftUserType } from '@/redux/shifts/types'

const sortByPosition = (a: RoomType, b: RoomType) => {
  a.position = a?.position ?? Number.NEGATIVE_INFINITY
  b.position = b?.position ?? Number.NEGATIVE_INFINITY
  return a.workshift_id > b.workshift_id
    ? 1
    : a.workshift_id < b.workshift_id
    ? -1
    : a.position > b.position
    ? 1
    : a.position < b.position
    ? -1
    : 0
}

export const initAllocatedNormalize = (
  rooms: Record<string, RoomType>,
): Record<string, string[]> => {
  return Object.values(rooms)
    .filter((room: RoomType) => room.workshift_id)
    .sort(sortByPosition)
    .reduce<Record<string, string[]>>(
      (acc, room) => ({
        ...acc,
        [room.workshift_id]: acc?.[room.workshift_id]
          ? [...acc[room.workshift_id], room.id]
          : [room.id],
      }),
      {},
    )
}

export const allocateNormalize = (
  attendants: ShiftUserType[],
  allocated: Record<string, string[]>,
): Record<string, string[]> => {
  return attendants.reduce<Record<string, string[]>>((acc, attendant) => {
    const findInitState = allocated[attendant.workshift_id]
    return { ...acc, [attendant.workshift_id]: findInitState ?? [] }
  }, {})
}

export const basketNormalize = (rooms: Record<string, RoomType>): string[] => {
  return Object.values(rooms)
    .filter((room: RoomType) => !room.workshift_id)
    .reduce((acc: string[], room) => [...acc, room.id], [])
}
