import Icon from '@ant-design/icons'

const SearchSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53314 3.70005C6.31148 3.70005 3.6998 6.31172 3.6998 9.53338C3.6998 12.755 6.31148 15.3667 9.53314 15.3667C11.1213 15.3667 12.5613 14.732 13.6132 13.7025C13.6146 13.701 13.6161 13.6996 13.6176 13.6982C14.6969 12.6395 15.3665 11.1646 15.3665 9.53338C15.3665 6.31172 12.7548 3.70005 9.53314 3.70005ZM15.0834 14.1721C16.1341 12.9164 16.7665 11.2987 16.7665 9.53338C16.7665 5.53852 13.528 2.30005 9.53314 2.30005C5.53828 2.30005 2.2998 5.53852 2.2998 9.53338C2.2998 13.5282 5.53828 16.7667 9.53314 16.7667C11.2583 16.7667 12.8424 16.1628 14.0856 15.1547L16.5131 17.5031C16.7909 17.772 17.2341 17.7646 17.5029 17.4868C17.7717 17.2089 17.7644 16.7657 17.4865 16.4969L15.0834 14.1721Z"
      fill="#9CA0AA"
    />
  </svg>
)

const SearchIcon = (props: any) => <Icon component={SearchSvg} {...props} />

export default SearchIcon
