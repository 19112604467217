import { AxiosResponse } from 'axios'
import store from './redux/store'
import axios from './config/axiosInterceptor'
import { getUserDetails, loading, logout } from './views/Login/ducks/actions'

const getToken = async () => {
  const token: { refreshToken: string; accessToken: string } = JSON.parse(
    localStorage.getItem('@maitretoken') as string,
  )
  const dispatch = store?.dispatch
  if (token) {
    dispatch(loading(true))
    axios
      .post(
        `refresh`,
        {
          refreshToken: token?.refreshToken,
        },
        {
          headers: {
            'content-Type': 'application/json',
          },
        },
      )
      .then(async (res: AxiosResponse<{ accessToken: string }>) => {
        const newTokens = JSON.stringify({
          ...token,
          accessToken: res?.data?.accessToken,
        })
        //   dispatch(setToken(res?.data?.accessToken));
        localStorage.setItem('@maitretoken', newTokens)
        dispatch(getUserDetails())
        dispatch(loading(false))
      })
      .catch(() => {
        localStorage.removeItem('@maitretoken')
        store.dispatch(logout())
      })
    // .finally(() => dispatch(stopLoading()));
  }
}

const urlParams = new URLSearchParams(window.location.search)
const code = urlParams.get('code')
if (!code) {
  getToken()
}
