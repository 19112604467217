import * as action_types from './constants'
import { Filter, User, UserDetail, UserlistFilters, UsersCount } from './types'

export interface UsersStateType {
  userListing: {
    loading: boolean
    list: User[]
  }
  userListFilters: UserlistFilters
  userFormData: { locations: Filter[]; userTypes: Filter[]; roles: Filter[] }
  userDetails: UserDetail | null
  ukgAccountsList: { label: string; value: string }[]
}

const initialState: UsersStateType = {
  userListing: {
    loading: false,
    list: [],
  },
  userListFilters: { departments: [], locations: [], roles: [], statuses: [], userTypes: [] },
  userFormData: { locations: [], userTypes: [], roles: [] },
  userDetails: null,
  ukgAccountsList: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.USER_SET_STATE:
      return {
        ...state,
        userListing: { ...state.userListing, loading: action.payload.loading },
      }

    case action_types.USER_DETAIL_SET_STATE:
      return {
        ...state,
        userDetails: { ...state.userDetails, loading: action.payload.loading },
      }

    case action_types.USERLISTING:
      return { ...state, userListing: { list: data } }

    case action_types.USERLISTFILTERS:
      return { ...state, userListFilters: data }
    case action_types.USERFORMDATA:
      return { ...state, userFormData: { ...state?.userFormData, locations: data?.locations } }
    case action_types.USERDETAILS:
      return { ...state, userDetails: data }

    case action_types.GET_UKG_CREDENTIALS:
      return { ...state, ukgAccountsList: data }

    case action_types.GETHOTELROLE:
      return { ...state, userFormData: { ...state?.userFormData, roles: data } }
    default:
      return state
  }
}
