import { AnyAction } from 'redux'
import { Pagination } from '@/redux/types'
import { UserType } from '@/redux/user/types'
import actions from './actions'

export type IUsersState = {
  loading: boolean
  error: Record<string, string | null>
  data: UserType[]
  pagination: Pagination
  activeUser: null | UserType
}

const initialState: IUsersState = {
  loading: false,
  error: {},
  data: [],
  activeUser: null,
  pagination: {
    limit: 20,
    page: 1,
    totalPages: 1,
    totalDocs: 0,
  },
}

const reducer = (state: IUsersState = initialState, action: AnyAction): IUsersState => {
  switch (action.type) {
    case actions.SET_LOADING:
      return { ...state, loading: action.data }
    case actions.SET_ERROR:
      return { ...state, ...action.payload }
    case actions.GET_USERS_SUCCESS: {
      const {
        users,
        pagination: { limit, page, totalPages, totalDocs },
      } = action?.data

      return {
        ...state,
        data: users,
        pagination: {
          limit,
          page,
          totalPages,
          totalDocs,
        },
      }
    }
    case actions.SET_ACTIVE_USER:
      return { ...state, activeUser: action.data }
    case actions.CLEAR_ACTIVE_USER:
      return { ...state, activeUser: null }
    default:
      return state
  }
}

export default reducer
