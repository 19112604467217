import { AnyAction } from 'redux'
import actions from './actions'

export interface IMeetingState {
  loading: boolean
  data: any
}

const initialState: IMeetingState = {
  loading: false,
  data: {},
}

const reducer = (state: IMeetingState = initialState, action: AnyAction): IMeetingState => {
  switch (action.type) {
    case actions.SET_LOADING:
      return { ...state, loading: action.data }
    case actions.GET_MESSAGE_SUCCESS:
      return { ...state, data: action.data }
    case actions.SEND_MESSAGE_SUCCESS:
      return { ...state, data: action.data }
    default:
      return state
  }
}

export default reducer
