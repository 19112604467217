import Icon from '@ant-design/icons'

const EditSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
    <path
      d="M20.5352 13.0797L21.1115 12.5034C21.502 12.1128 21.502 11.4797 21.1115 11.0891L19.3248 9.30241C18.9343 8.91189 18.3011 8.91189 17.9106 9.30241L10.4496 16.7634L10.4496 19.9643L13.6505 19.9643L19.0753 14.5396L17.4748 12.9391"
      stroke="#7F8385"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {/* <rect
      x="0.959942"
      y="0.498516"
      width="29.003"
      height="29.003"
      rx="14.5015"
      stroke="#B9BBBD"
      strokeOpacity="0.22"
      strokeWidth="0.997032"
    /> */}
  </svg>
)

const EditIconCircle = (props: any) => <Icon component={EditSvg} {...props} />

export default EditIconCircle
