import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const RoomIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 22, 13',
}: SvgIconType) => {
  return (
    <svg width="22" height="13" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {(
          <>
            <path
                d="M8.04688 7.20547C7.47813 7.77422 6.8 8.05859 6.0125 8.05859C5.225 8.05859 4.54688 7.77422 3.97813 7.20547C3.40938 6.63672 3.125 5.95859 3.125 5.17109C3.125 4.38359 3.40938 3.70547 3.97813 3.13672C4.54688 2.56797 5.225 2.28359 6.0125 2.28359C6.8 2.28359 7.47813 2.56797 8.04688 3.13672C8.61563 3.70547 8.9 4.38359 8.9 5.17109C8.9 5.95859 8.61563 6.63672 8.04688 7.20547ZM6.93125 4.25234C6.69063 3.98984 6.38438 3.85859 6.0125 3.85859C5.64062 3.85859 5.32344 3.98984 5.06094 4.25234C4.82031 4.49297 4.7 4.79922 4.7 5.17109C4.7 5.54297 4.82031 5.86016 5.06094 6.12266C5.32344 6.36328 5.64062 6.48359 6.0125 6.48359C6.38438 6.48359 6.69063 6.36328 6.93125 6.12266C7.19375 5.86016 7.325 5.54297 7.325 5.17109C7.325 4.79922 7.19375 4.49297 6.93125 4.25234ZM17.825 2.28359C18.8313 2.28359 19.6953 2.64453 20.4172 3.36641C21.1391 4.08828 21.5 4.95234 21.5 5.95859V12.2586C21.5 12.4117 21.4453 12.543 21.3359 12.6523C21.2484 12.7398 21.1281 12.7836 20.975 12.7836H20.45C20.2969 12.7836 20.1656 12.7398 20.0563 12.6523C19.9688 12.543 19.925 12.4117 19.925 12.2586V10.6836H2.075V12.2586C2.075 12.4117 2.02031 12.543 1.91094 12.6523C1.82344 12.7398 1.70312 12.7836 1.55 12.7836H1.025C0.871875 12.7836 0.740625 12.7398 0.63125 12.6523C0.54375 12.543 0.5 12.4117 0.5 12.2586V0.708594C0.5 0.555469 0.54375 0.435156 0.63125 0.347656C0.740625 0.238281 0.871875 0.183594 1.025 0.183594H1.55C1.70312 0.183594 1.82344 0.238281 1.91094 0.347656C2.02031 0.435156 2.075 0.555469 2.075 0.708594V9.10859H9.95V2.80859C9.95 2.65547 9.99375 2.53516 10.0813 2.44766C10.1906 2.33828 10.3219 2.28359 10.475 2.28359H17.825ZM19.925 9.10859V5.95859C19.925 5.38984 19.7172 4.89766 19.3016 4.48203C18.8859 4.06641 18.3938 3.85859 17.825 3.85859H11.525V9.10859H19.925Z"
                fill="#001238"/>
          </>
      )}
    </svg>
  )
}

export default RoomIcon
