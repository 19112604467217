import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const DeliveryServiceIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.9998 4.70005C2.83412 4.70005 2.6998 4.83436 2.6998 5.00005V12.5481C3.17452 11.5911 4.16863 10.9364 5.31015 10.9364C6.66653 10.9364 7.81478 11.8607 8.13087 13.1182H11.7826V4.70005H2.9998ZM13.1826 6.02732V4.00005C13.1826 3.61345 12.8692 3.30005 12.4826 3.30005H2.9998C2.06092 3.30005 1.2998 4.06117 1.2998 5.00005V13.8182C1.2998 14.2048 1.61321 14.5182 1.9998 14.5182H2.48943C2.80551 15.7757 3.95376 16.7 5.31015 16.7C6.66653 16.7 7.81478 15.7757 8.13087 14.5182H12.4205C12.7365 15.7757 13.8848 16.7 15.2412 16.7C16.5986 16.7 17.7475 15.7744 18.0626 14.5155C18.4198 14.4837 18.6998 14.1837 18.6998 13.8182V9.45459C18.6998 9.26756 18.625 9.0883 18.4919 8.9568L15.7333 6.22953C15.6023 6.09998 15.4254 6.02732 15.2412 6.02732H13.1826ZM13.1826 7.42732V11.7836C13.71 11.2595 14.4392 10.9364 15.2412 10.9364C16.0431 10.9364 16.7723 11.2595 17.2998 11.7836V9.74689L14.9536 7.42732H13.1826ZM5.31015 12.3364C4.47035 12.3364 3.80325 13.0074 3.80325 13.8182C3.80325 14.6291 4.47035 15.3 5.31015 15.3C6.14995 15.3 6.81705 14.6291 6.81705 13.8182C6.81705 13.0074 6.14995 12.3364 5.31015 12.3364ZM15.2412 12.3364C14.4014 12.3364 13.7343 13.0074 13.7343 13.8182C13.7343 14.6291 14.4014 15.3 15.2412 15.3C16.081 15.3 16.7481 14.6291 16.7481 13.8182C16.7481 13.0074 16.081 12.3364 15.2412 12.3364Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.9998 4.70005C2.83412 4.70005 2.6998 4.83436 2.6998 5.00005V12.5481C3.17452 11.5911 4.16863 10.9364 5.31015 10.9364C6.66653 10.9364 7.81478 11.8607 8.13087 13.1182H11.7826V4.70005H2.9998ZM13.1826 6.02732V4.00005C13.1826 3.61345 12.8692 3.30005 12.4826 3.30005H2.9998C2.06092 3.30005 1.2998 4.06117 1.2998 5.00005V13.8182C1.2998 14.2048 1.61321 14.5182 1.9998 14.5182H2.48943C2.80551 15.7757 3.95376 16.7 5.31015 16.7C6.66653 16.7 7.81478 15.7757 8.13087 14.5182H12.4205C12.7365 15.7757 13.8848 16.7 15.2412 16.7C16.5986 16.7 17.7475 15.7744 18.0626 14.5155C18.4198 14.4837 18.6998 14.1837 18.6998 13.8182V9.45459C18.6998 9.26756 18.625 9.0883 18.4919 8.9568L15.7333 6.22953C15.6023 6.09998 15.4254 6.02732 15.2412 6.02732H13.1826ZM13.1826 7.42732V11.7836C13.71 11.2595 14.4392 10.9364 15.2412 10.9364C16.0431 10.9364 16.7723 11.2595 17.2998 11.7836V9.74689L14.9536 7.42732H13.1826ZM5.31015 12.3364C4.47035 12.3364 3.80325 13.0074 3.80325 13.8182C3.80325 14.6291 4.47035 15.3 5.31015 15.3C6.14995 15.3 6.81705 14.6291 6.81705 13.8182C6.81705 13.0074 6.14995 12.3364 5.31015 12.3364ZM15.2412 12.3364C14.4014 12.3364 13.7343 13.0074 13.7343 13.8182C13.7343 14.6291 14.4014 15.3 15.2412 15.3C16.081 15.3 16.7481 14.6291 16.7481 13.8182C16.7481 13.0074 16.081 12.3364 15.2412 12.3364Z"
            fill="#9CA0AA"
          />
        </>
      )}
    </svg>
  )
}

export default DeliveryServiceIcon
