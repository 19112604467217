import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const ChecklistIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 17',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="17" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {(
        <>
          <path
              d="M3.18049 5.41712C3.02439 5.57124 2.79024 5.68683 2.51707 5.68683C2.28293 5.72536 2.00976 5.60977 1.85366 5.41712L0.292683 3.87596C-0.097561 3.5292 -0.097561 2.95126 0.292683 2.6045C0.643902 2.21921 1.22927 2.21921 1.58049 2.6045L2.47805 3.45214L4.62439 1.06334C4.97561 0.716578 5.56097 0.678049 5.95122 1.02481C6.30244 1.37157 6.34146 1.94951 5.99024 2.3348L3.18049 5.41712ZM3.18049 11.5818C3.02439 11.7359 2.79024 11.8515 2.51707 11.8515C2.28293 11.89 2.00976 11.7744 1.85366 11.5818L0.292683 10.0406C-0.097561 9.69385 -0.097561 9.11592 0.292683 8.73063C0.643902 8.38386 1.22927 8.38386 1.58049 8.73063L2.47805 9.61679L4.62439 7.22799C4.97561 6.88123 5.56097 6.8427 5.95122 7.18946C6.30244 7.49769 6.34146 8.11416 5.99024 8.49945L3.18049 11.5818ZM8.44878 4.14567C7.90244 4.14567 7.51219 3.76038 7.51219 3.22097C7.51219 2.72009 7.90244 2.29627 8.44878 2.29627H19.0634C19.5707 2.29627 20 2.72009 20 3.22097C20 3.76038 19.5707 4.14567 19.0634 4.14567H8.44878ZM7.51219 9.38562C7.51219 8.88474 7.90244 8.46092 8.44878 8.46092H19.0634C19.5707 8.46092 20 8.88474 20 9.38562C20 9.92503 19.5707 10.3103 19.0634 10.3103H8.44878C7.90244 10.3103 7.51219 9.92503 7.51219 9.38562ZM6.26341 15.5503C6.26341 15.0494 6.65366 14.6256 7.2 14.6256H19.0634C19.5707 14.6256 20 15.0494 20 15.5503C20 16.0897 19.5707 16.475 19.0634 16.475H7.2C6.65366 16.475 6.26341 16.0897 6.26341 15.5503ZM2.51707 16.7832C1.81463 16.7832 1.26829 16.2438 1.26829 15.5503C1.26829 14.8953 1.81463 14.3173 2.51707 14.3173C3.18049 14.3173 3.76585 14.8953 3.76585 15.5503C3.76585 16.2438 3.18049 16.7832 2.51707 16.7832Z"
              fill="#001238"/>
        </>
      )}
    </svg>
  )
}

export default ChecklistIcon
