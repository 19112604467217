import { CleaningResultType, GuestType } from '@/redux/room/types'
import { Statuses } from '@/config/constants'
import * as action_types from './constants'

export interface AllocationState {
  roomAllocation: []
  workshiftAllocation: []
}

const initialState = {
  roomAllocation: [],
  workshiftAllocation: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.ROOM_FOR_ALLOCATION:
      return { ...state, roomAllocation: data }

    case action_types.WORKSHIFT_FOR_ALLOCATION:
      return { ...state, workshiftAllocation: data }

    default:
      return state
  }
}
