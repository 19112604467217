import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const UserIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.394 0.86002C11.5876 0.525377 12.0158 0.411023 12.3505 0.604604C13.2411 1.11979 13.8428 2.08444 13.8428 3.19045C13.8428 4.29647 13.2411 5.26112 12.3505 5.7763C12.0158 5.96988 11.5876 5.85553 11.394 5.52089C11.2005 5.18625 11.3148 4.75804 11.6495 4.56446C12.1253 4.28922 12.4428 3.77651 12.4428 3.19045C12.4428 2.6044 12.1253 2.09169 11.6495 1.81645C11.3148 1.62287 11.2005 1.19466 11.394 0.86002ZM14.4392 7.98576C14.6706 7.67606 15.1092 7.61258 15.4189 7.84398C16.0701 8.33052 16.6308 9.13264 17.0242 9.93267C17.4173 10.7322 17.7 11.6447 17.7 12.4047C17.7 13.1015 17.1759 13.7952 16.3785 13.7952H16C15.6134 13.7952 15.3 13.4818 15.3 13.0952C15.3 12.7086 15.6134 12.3952 16 12.3952H16.2999C16.2974 11.9312 16.1084 11.2429 15.7678 10.5505C15.4253 9.85382 14.9859 9.26803 14.581 8.9655C14.2713 8.73411 14.2078 8.29546 14.4392 7.98576Z"
            fill="#16181D"
          />
          <path
            d="M0.961426 13.1501C0.961426 10.7304 2.22144 7.97015 7.17572 7.97015C12.13 7.97015 13.39 10.7304 13.39 13.1501C13.39 13.5351 13.1063 13.8471 12.7564 13.8471H1.59504C1.2451 13.8471 0.961426 13.5351 0.961426 13.1501Z"
            fill="#16181D"
          />
          <path
            d="M10.1824 3.19117C10.1824 4.86138 8.81303 6.21535 7.12384 6.21535C5.43465 6.21535 4.06529 4.86138 4.06529 3.19117C4.06529 1.52096 5.43465 0.166992 7.12384 0.166992C8.81303 0.166992 10.1824 1.52096 10.1824 3.19117Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.394 0.86002C11.5876 0.525377 12.0158 0.411023 12.3505 0.604604C13.2411 1.11979 13.8428 2.08444 13.8428 3.19045C13.8428 4.29647 13.2411 5.26112 12.3505 5.7763C12.0158 5.96988 11.5876 5.85553 11.394 5.52089C11.2005 5.18625 11.3148 4.75804 11.6495 4.56446C12.1253 4.28922 12.4428 3.77651 12.4428 3.19045C12.4428 2.6044 12.1253 2.09169 11.6495 1.81645C11.3148 1.62287 11.2005 1.19466 11.394 0.86002ZM14.4392 7.98576C14.6706 7.67606 15.1092 7.61258 15.4189 7.84398C16.0701 8.33053 16.6308 9.13264 17.0242 9.93267C17.4173 10.7322 17.7 11.6447 17.7 12.4047C17.7 13.1015 17.1759 13.7952 16.3785 13.7952H16C15.6134 13.7952 15.3 13.4818 15.3 13.0952C15.3 12.7086 15.6134 12.3952 16 12.3952H16.2999C16.2974 11.9312 16.1084 11.2429 15.7678 10.5505C15.4253 9.85382 14.9859 9.26803 14.581 8.9655C14.2713 8.73411 14.2078 8.29546 14.4392 7.98576Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9372 12.4471C11.8351 11.7837 11.5803 11.1887 11.133 10.7249C10.5415 10.1116 9.40388 9.51073 7.17572 9.51073C4.94756 9.51073 3.80994 10.1116 3.21844 10.7249C2.77113 11.1887 2.51632 11.7837 2.41421 12.4471H11.9372ZM7.12384 4.81535C8.05479 4.81535 8.78239 4.07331 8.78239 3.19117C8.78239 2.30904 8.05479 1.56699 7.12384 1.56699C6.19289 1.56699 5.46529 2.30904 5.46529 3.19117C5.46529 4.07331 6.19289 4.81535 7.12384 4.81535ZM7.12384 6.21535C8.81303 6.21535 10.1824 4.86138 10.1824 3.19117C10.1824 1.52096 8.81303 0.166992 7.12384 0.166992C5.43465 0.166992 4.06529 1.52096 4.06529 3.19117C4.06529 4.86138 5.43465 6.21535 7.12384 6.21535ZM7.17572 8.11073C2.22144 8.11073 0.961426 10.8049 0.961426 13.1668C0.961426 13.5425 1.2451 13.8471 1.59504 13.8471H12.7564C13.1063 13.8471 13.39 13.5425 13.39 13.1668C13.39 10.8049 12.13 8.11073 7.17572 8.11073Z"
            fill="#16181D"
          />
        </>
      )}
    </svg>
  )
}

export default UserIcon
