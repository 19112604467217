// import OAuth from '@DevimaSolutions/o-auth'
import axios from '@/config/axiosInterceptor'
import { RefreshShiftsResponseType, ShiftsResponseType } from '@/redux/shifts/types'

export const fetchWorkShiftsList = async (
  hotelID: string,
  date: string,
  type: string,
): Promise<ShiftsResponseType> => {
  const { data } = await axios.get(`getWorkshiftForAllocation/${hotelID}/${date}/${type}`)
  return data?.shifts ?? []
}

// export const refreshWorkShiftsList = (): Promise<RefreshShiftsResponseType> => {
//   return OAuth().axios.post(`/api/manager/refresh-workshifts`)
// }
