import * as action_types from './constants'

export interface EditHotelType {
  schedulingUsers: []
  locationsData: []
  ukgAccountList: {
    ukg: []
    homebase: []
  }
  schedulersData: {
    defaultShiftDuration: number
    isRoleSelectionAllowed: boolean
    schedulingData: {
      ukg: {
        credential_id: string
        credential_type: string
        hotel_id: string
        location_name: string
        id: string
        status: boolean
      }
      homebase: {
        credential_id: string
        credential_type: string
        hotel_id: string
        location_name: string
        id: string
        status: boolean
      }
    }
  }
  ukgSchedulingData: {
    credential_id: string
    credential_type: string
    hotel_id: string
    id: string
    location_name: string
  }
  ukgCredentialsData: {
    ukg_id: string
    username: string
    credential_name: string
    password: string
    api_key: string
    company_id: string
    shared_users: string[]
  }
}

const initialState = {
  schedulingUsers: [],
  ukgAccountList: [],
  ukgCredentialsData: {},
  ukgSchedulingData: {},
  schedulersData: {},
  locationsData: [],
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.SCHEDULING_USERS:
      return { ...state, schedulingUsers: data }

    case action_types.UKG_ACCOUNT_LIST:
      return { ...state, ukgAccountList: data }

    case action_types.UKG_CREDENTIALS_DATA:
      return { ...state, ukgCredentialsData: data }

    case action_types.UKG_SCHEDULING_DATA:
      return { ...state, ukgSchedulingData: data }

    case action_types.SCHEDULERS_DATA:
      return { ...state, schedulersData: data }

    case action_types.GET_HOTEL_LOCATIONS:
      return { ...state, locationsData: data }

    case action_types.CLEAR_DATA:
      return { ...state, ukgCredentialsData: {} }

    default:
      return state
  }
}
