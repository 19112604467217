import Icon from '@ant-design/icons'

const EditSvg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.9216 21.0504L34.8912 19.0808C35.2817 18.6903 35.2817 18.0571 34.8912 17.6666L30.3335 13.109C29.943 12.7184 29.3099 12.7184 28.9193 13.109L14.3876 27.6407L14.3876 33.6125L20.3595 33.6125L31.1194 22.8526L28.1335 19.8667"
      stroke="#1B1C1D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const EditIcon = (props: any) => <Icon component={EditSvg} {...props} />

export default EditIcon
