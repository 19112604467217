import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)

const LoadingSpinner: React.FunctionComponent = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress size={80} color="primary" />
    </Backdrop>
  )
}

export default LoadingSpinner
