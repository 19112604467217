export const GET_ROOM_NOTES = 'GET_ROOM_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'

export interface CreateNoteType {
  id?: string
  data: FormData | { text: string }
}

export enum Statuses {
  not_completed = 'Not Completed',
  completed = 'Completed',
  in_progress = 'In Progress',
}
