import React, { Fragment } from 'react'
import * as action_types from './constants'

export interface LocationStateType {
  permissionsTemplates: {
    templates: {
      department_name: string
      template_name: string
      template_display_name: string
    }[]
    permissions: {
      [key: string]: {
        title: string
        description: any
        template_permissions: {
          template_name: string
          is_changeable: boolean
          access_level?: number | undefined
          is_checked: boolean
          show_access_selector: boolean
          is_disabled: boolean
        }[]
      }[]
    }
  }
}

const initialState: LocationStateType = {
  permissionsTemplates: {
    templates: [
      {
        template_display_name: 'General Manager',
        department_name: 'Hotel Management',
        template_name: 'General Manager',
      },
      {
        template_display_name: 'Front Desk Manager',
        department_name: 'Front Office',
        template_name: 'Front Desk  Manager',
      },
      {
        template_display_name: 'Housekeeping Manager',
        department_name: 'Housekeeping',
        template_name: 'Housekeeping Manager',
      },
      {
        template_display_name: 'Room Attendant',
        department_name: 'Housekeeping',
        template_name: 'Room Attendant',
      },
      {
        template_display_name: 'House Person',
        department_name: 'Housekeeping',
        template_name: 'House Person',
      },
      {
        template_display_name: 'Administrator Role Template',
        department_name: 'Any Department',
        template_name: 'Administrator Role Template',
      },
      {
        template_display_name: 'Manager Role Template',
        department_name: 'Any Department',
        template_name: 'Manager Role Template',
      },
      {
        template_display_name: 'Room Attendant Role Template',
        department_name: 'Housekeeping',
        template_name: 'Room Attendant Role Template',
      },
      {
        template_display_name: 'Staff Role Template',
        department_name: 'Any Department',
        template_name: 'Staff Role Template',
      },
    ],
    permissions: {
      Operations: [
        {
          title: 'Manage location settings',
          description:
            'Update any location settings, such as PMS, scheduling, hotel floor layout, departments & roles, and other settings.',
          template_permissions: [
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
        },
        {
          title: 'Manage Users',
          description: 'View, create, edit, delete, deactivate the users within the location.',
          template_permissions: [
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
        },
      ],
      'Dashboard & Reports': [
        {
          title: 'View location dashboard',
          description:
            'View such data as morning meeting message, shifts schedule, room occupancy, deliveries, cleaning services and tasks statistics.',
          template_permissions: [
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
        },
        {
          description: 'Add, edit, delete the morning meeting message for all the location users.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
          title: 'Edit the morning meeting message',
        },
        {
          description:
            'View and download all the location reports, such as room status, tasks, productivity, and other reports.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
          title: 'Access reports',
        },
      ],
      Rooms: [
        {
          title: 'View room status board',
          description: 'View room statuses, guest details, and room cleaning data.',
          template_permissions: [
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              access_level: 1,
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
        },
        {
          title: 'Manage room allocation',
          description:
            'Access to the room allocation board and ability to allocate the rooms to the room attendants.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: 'generic_administration',
            },
          ],
        },
        {
          title: 'Perform room cleaning and turn-down',
          description:
            'Ability to record the room cleaning and turn-down via the Cloudbeds Housekeeping mobile application.',
          template_permissions: [
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },

            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: false,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },

            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
          ],
        },
        {
          title: 'Perform room inspection',
          description:
            'Ability to mark clean rooms or rooms where turn-down is completed as inspected.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
          ],
        },
        {
          title: 'Manage room statuses',
          description:
            'Change the room statuses in case of any discrepancies between Cloudbeds Housekeeping and PMS or front office and housekeeping. This does not include the room cleaning and inspection capabilities.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
          ],
        },
        {
          title: 'Manage room service preference time',
          description: 'Add, edit, delete the cleaning or turn-down preference time.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: false,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
          ],
        },
        {
          title: 'Leave notes',
          description:
            'Ability to add notes under each room if View room status board privilege is ON.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
          ],
        },
      ],
      Tasks: [
        {
          description: `Ability to create and assign the tasks to the location users. This includes the
              ability to manage own tasks too. \n 

              If the user cannot assign a task to a specific
              employee, an Open task for the employee’s role group or department will be created.`,
          title: 'Create and assign tasks to the users',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: false,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: false,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: false,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
          ],
        },
        {
          title: 'Manage tasks',
          description: 'Edit, change status, or delete the tasks.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
          ],
        },
        {
          title: 'Pick up and complete tasks',
          description: 'Ability to assign yourself and complete these tasks.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
          ],
        },
        {
          title: 'View tasks',
          description: 'Ability to view the tasks.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              access_level: 5,
              template_name: '',
            },
          ],
        },
        {
          title: 'Leave notes',
          description: 'Ability to add notes under the tasks that the user can view.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 5,
              template_name: '',
            },
          ],
        },
      ],
      'Cheklists & SOP': [
        {
          title: 'Manage checklists and SOP',
          description: 'Create, edit, delete checklists, to-do items, and SOP.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 5,
            },
          ],
        },
        {
          title: 'View checklists and SOP',
          description: `Ability to view the checklists, to-do items, and SOP.`,
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: true,
              template_name: '',
              access_level: 5,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              template_name: '',
              access_level: 1,
            },
          ],
        },
        {
          title: 'Leave notes',
          description:
            'Ability to add notes under each room if View room status board privilege is ON.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              access_level: 1,
              show_access_selector: false,
              template_name: '',
            },
          ],
        },
      ],
      Projects: [
        {
          description: 'Create, edit, change status, or delete the location projects.',
          title: 'Manage projects',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
          ],
        },
        {
          description: 'Ability to view the location projects and its tasks.',
          title: 'View projects',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
          ],
        },
        {
          description: 'Leave notes',
          title: 'Ability to add notes under the projects that the user can view.',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
          ],
        },
      ],
      Schedule: [
        {
          title: 'Manage employees schedule',

          description:
            'Create, edit, delete employee shifts in Cloudbeds Housekeeping and synchronize the schedule with a scheduling system (if being used).',
          template_permissions: [
            {
              is_changeable: true,
              is_checked: true,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: true,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
            {
              is_changeable: false,
              is_checked: false,
              is_disabled: true,
              show_access_selector: false,
              access_level: 1,
              template_name: '',
            },
          ],
        },
      ],
    },
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.PERMISSIONTEMPLATES:
      return { ...state, permissionsTemplates: data }

    default:
      return state
  }
}
