import { AnyAction } from 'redux'
import { UserType } from '@/redux/user/types'
import { Pagination } from '@/redux/types'

export interface IGetUsersResponse {
  users: UserType[]
  pagination: Pagination
}

export interface IUpdateUserRequest {
  values: unknown
  id: string
}

export interface IResetUserPasswordRequest {
  password: string
  id: string
}

export interface IDeleteUserRequest {
  history: unknown
  id: string
}

export interface IGetUsersRequest {
  limit: number
  page: number
}

interface IAddNewUserRequest {
  data: FormData
  history: unknown
}

const actions = {
  SET_LOADING: 'admin/SET_LOADING',
  GET_USERS_REQUEST: 'admin/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'admin/GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'admin/GET_USERS_ERROR',
  UPDATE_USER_REQUEST: 'admin/UPDATE_USER_REQUEST',
  DELETE_USER_REQUEST: 'admin/DELETE_USER_REQUEST',
  GET_ACTIVE_USER_REQUEST: 'admin/GET_USER_REQUEST',
  SET_ACTIVE_USER: 'admin/SET_ACTIVE_USER',
  CLEAR_ACTIVE_USER: 'admin/CLEAR_ACTIVE_USER',
  RESET_USER_PASSWORD_REQUEST: 'admin/RESET_USER_PASSWORD_REQUEST',
  ADD_NEW_USER: 'admin/ADD_NEW_USER',
  SET_ERROR: 'admin/SET_ERROR',
}

export const loading = (data: boolean): AnyAction => ({
  type: actions.SET_LOADING,
  data,
})

export const getUsers = (data: IGetUsersRequest): AnyAction => ({
  type: actions.GET_USERS_REQUEST,
  data,
})

export const updateUser = (data: IUpdateUserRequest): AnyAction => ({
  type: actions.UPDATE_USER_REQUEST,
  data,
})

export const deleteUser = (data: IDeleteUserRequest): AnyAction => ({
  type: actions.DELETE_USER_REQUEST,
  data,
})

export const getActiveUser = (id: string): AnyAction => ({
  type: actions.GET_ACTIVE_USER_REQUEST,
  id,
})

export const setActiveUser = (data: UserType | null): AnyAction => ({
  type: actions.SET_ACTIVE_USER,
  data,
})

export const clearActiveUser = (): AnyAction => ({
  type: actions.CLEAR_ACTIVE_USER,
})

export const resetUserPassword = (data: IResetUserPasswordRequest): AnyAction => ({
  type: actions.RESET_USER_PASSWORD_REQUEST,
  data,
})

export const getUsersSuccess = (data: IGetUsersResponse): AnyAction => ({
  type: actions.GET_USERS_SUCCESS,
  data,
})

export const addNewUser = (data: IAddNewUserRequest): AnyAction => ({
  type: actions.ADD_NEW_USER,
  data,
})

export const setError = (error: Record<string, string | null>): AnyAction => {
  return {
    type: actions.SET_ERROR,
    payload: { error },
  }
}

export default actions
