import { combineReducers } from 'redux'
import authentication, { AuthState } from '@/views/Login/ducks/reducers'
import authenticationNew, { AuthStateNew } from '@/views/NewLogin/ducks/reducers'
import auth, { AuthStateType } from './auth/reducers'
import rooms, { RoomsStateType } from './rooms/reducers'
import room, { RoomStateType } from './room/reducers'
import meeting, { IMeetingState } from './meeting/reducers'
import user, { IUserState } from './user/reducers'
import users, { IUsersState } from './users/reducers'
import shifts, { ShiftStateType } from './shifts/reducers'
import hotels, { IHotelsState } from './hotels/reducers'
import allocations, { AllocationsStateType } from './allocations/reducers'
import turndownAllocations, { TurnDownAllocationsStateType } from './turndownAllocations/reducers'
import notes, { NotesStateType } from './notes/reducers'
import tasks from '../views/Tasks/ducks/reducers'
import projects from '../views/Projects/ducks/reducers'
import checklist from '../views/DailyChecklist/ducks/reducers'
import newChecklist from '../views/DailyChecklists/ducks/reducers'
import dashboard from '../views/Dashboard/ducks/reducers'
import setting from '../views/Settings/ducks/reducers'
import note from '../views/Notes/ducks/reducers'
import usersDetail from '../views/UserDetails/ducks/reducers'
import department from '../components/Forms/DepartmentRolesForm/ducks/reducers'
import locations, { LocationStateType } from '../views/locations/ducks/reducers'
import rolePrevileges, { RoleStateType } from '../views/UsersLocation/ducks/reducers'
import adminUsers, { UsersStateType } from '../views/Users/ducks/reducers'
import editHotel, { EditHotelType } from '../views/EditHotel/components/ducks/reducers'
import userlist, { ReportsStateType } from '../views/Reports/ducks/reducers'
import adminLocations, { AdminLocationStateType } from '../views/AdminLocations/ducks/reducers'
import roomData, { RoomState } from '../views/Rooms/ducks/reducers'
import allocationData, { AllocationState } from '../views/Allocations/ducks/reducers'
import adminDelivery, { AdminDeliveryStateType } from '../views/DeliveryService/ducks/reducers'

export interface IRootState {
  auth: AuthStateType
  rooms: RoomsStateType
  room: RoomStateType
  meeting: IMeetingState
  authentication: AuthState
  authenticationNew: AuthStateNew
  user: IUserState
  users: IUsersState
  shifts: ShiftStateType
  hotels: IHotelsState
  roles: IHotelsState
  allocations: AllocationsStateType
  turndownAllocations: TurnDownAllocationsStateType
  notes: NotesStateType
  locations: LocationStateType
  adminUsers: UsersStateType
  rolePrevileges: RoleStateType
  editHotel: EditHotelType
  userlist: ReportsStateType
  adminLocations: AdminLocationStateType
  roomData: RoomState
  allocationData: AllocationState
  adminDelivery: AdminDeliveryStateType
}

export default combineReducers({
  auth,
  rooms,
  room,
  meeting,
  user,
  users,
  shifts,
  hotels,
  allocations,
  turndownAllocations,
  notes,
  tasks,
  projects,
  checklist,
  dashboard,
  setting,
  note,
  usersDetail,
  newChecklist,
  department,
  locations,
  adminUsers,
  rolePrevileges,
  editHotel,
  userlist,
  adminLocations,
  roomData,
  allocationData,
  adminDelivery,
  authentication,
  authenticationNew,
})
