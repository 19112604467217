import { FloorType, FloorTypServerResponse, RoomType } from '@/redux/rooms/types'

export const convertRoomsInFloorToObj = (floors: FloorTypServerResponse[]): FloorType[] => {
  return floors.map(({ rooms, ...another }: FloorTypServerResponse) => ({
    rooms: rooms.reduce(
      (obj: { [k: string]: RoomType }, room: RoomType) => ({
        ...obj,
        [room.id]: { ...room },
      }),
      {},
    ),
    ...another,
  }))
}

export const updateRoomById = (floors: FloorType[], room: RoomType): FloorType[] => {
  const { id, ...params } = room
  return floors.map((floor: FloorType) => {
    const activeRoom = floor.rooms[id]
    if (activeRoom) {
      return {
        ...floor,
        rooms: {
          ...floor.rooms,
          [id]: {
            ...activeRoom,
            ...params,
          },
        },
      }
    }
    return floor
  })
}

export const updateRoomsBySocket = (floors: FloorType[], rooms: RoomType[]): FloorType[] =>
  floors.map((floor: FloorType) => {
    rooms.forEach((room) => {
      const activeRoom = floor.rooms[room.id]
      if (!activeRoom) return
      floor.rooms[room.id] = { ...activeRoom, ...room }
    })
    return floor
  })
