import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const TasksIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 18, 17',
  active,
}: SvgIconType) => {
  return (
    <svg width="18" height="17" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {(
          <>
            <path
                d="M3.22481 2.74102C4.82168 1.14414 6.74668 0.345703 8.99981 0.345703C11.2529 0.345703 13.167 1.14414 14.742 2.74102C16.3389 4.31602 17.1373 6.23008 17.1373 8.4832C17.1373 10.7363 16.3389 12.6613 14.742 14.2582C13.167 15.8332 11.2529 16.6207 8.99981 16.6207C6.74668 16.6207 4.82168 15.8332 3.22481 14.2582C1.6498 12.6613 0.862305 10.7363 0.862305 8.4832C0.862305 6.23008 1.6498 4.31602 3.22481 2.74102ZM13.6264 3.85664C12.3576 2.56602 10.8154 1.9207 8.99981 1.9207C7.18418 1.9207 5.63106 2.56602 4.34043 3.85664C3.07168 5.12539 2.4373 6.66758 2.4373 8.4832C2.4373 10.2988 3.07168 11.852 4.34043 13.1426C5.63106 14.4113 7.18418 15.0457 8.99981 15.0457C10.8154 15.0457 12.3576 14.4113 13.6264 13.1426C14.917 11.852 15.5623 10.2988 15.5623 8.4832C15.5623 6.66758 14.917 5.12539 13.6264 3.85664ZM13.5936 6.18633C13.7904 6.3832 13.7904 6.56914 13.5936 6.74414L7.94981 12.3551C7.75293 12.552 7.56699 12.552 7.39199 12.3551L4.40606 9.36914C4.20918 9.17227 4.20918 8.98633 4.40606 8.81133L5.16074 8.05664C5.33574 7.88164 5.52168 7.88164 5.71856 8.05664L7.65449 10.0582L12.3139 5.43164C12.4889 5.25664 12.6748 5.26758 12.8717 5.46445L13.5936 6.18633Z"
                fill="#001238"/>
          </>
      )}
    </svg>
  )
}

export default TasksIcon
