import Icon from '@ant-design/icons'

const LeftArrowSvg = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 3L6 14L17 25"
      stroke="#1B1C1D"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const LeftArrowIcon = (props: any) => <Icon component={LeftArrowSvg} {...props} />

export default LeftArrowIcon
