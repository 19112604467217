import * as action_types from '@/views/NewLogin/ducks/constants'
import { Token } from '@/views/NewLogin/ducks/types'

export type AuthStateNew = {
  auth: {
    token: Token | null
    loading: boolean
  }
}

const initialState: AuthStateNew = {
  auth: {
    token: null,
    loading: false,
  },
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.SET_LOADING:
      return { ...state, auth: { ...action.payload } }

    case action_types.LOGIN:
      return { ...state, auth: { token: data, loading: false } }

    default:
      return state
  }
}
