import { AnyAction } from 'redux'
import actions from './actions'

export interface AuthStateType {
  loading: boolean
  error: {
    password: string | null
    email: string | null
  }
  passwordConfirm: boolean
  passwordExperationMessage: string | null
}

const initialState: AuthStateType = {
  loading: false,
  error: {
    password: null,
    email: null,
  },
  passwordConfirm: false,
  passwordExperationMessage: null
}

export default function reducer(
  state: AuthStateType = initialState,
  action: AnyAction,
): AuthStateType {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR:
      return { ...state, ...action.payload }
    case actions.SET_PASSWORD_EXPERATION_MESSAGE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
