import { AnyAction } from 'redux'
import { Hotel, HotelFloorType, HotelGroup, HotelType, RoomType } from '@/redux/hotels/types'
import actions, { IGetHotelGroupsResponse, IGetHotelsResponse } from './actions'
import { Pagination } from '../types'

export interface IHotelsState {
  data: Hotel[]
  rooms: RoomType[] | null
  floors: HotelFloorType[] | null
  startFromOne: any
  hotel: HotelType | null
  loading: boolean
  pagination: Pagination
  groups: HotelGroup[]
  error: Record<string, string | null>
  roles: { label: string; value: string }[]
}

const initialState: IHotelsState = {
  data: [],
  hotel: null,
  floors: null,
  rooms: null,
  startFromOne: '',
  loading: false,
  pagination: {
    limit: 20,
    page: 1,
    totalPages: 1,
    totalDocs: 0,
  },
  error: {},
  groups: [],
  roles: [{ label: '', value: '' }],
}

export default function reducer(
  state: IHotelsState = initialState,
  action: AnyAction,
): IHotelsState {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR:
      return { ...state, ...action.payload }
    case actions.GET_HOTELS_SUCCESS:
      const { hotels, pagination, roles } = action?.data as IGetHotelsResponse
      const { limit, page, totalPages, totalDocs } = { ...state.pagination, ...pagination }

      return {
        ...state,
        data: hotels,
        pagination: {
          limit,
          page,
          totalPages,
          totalDocs,
        },
        roles,
      }
    case actions.CLEAR_HOTELS:
      return {
        ...state,
        data: [],
        pagination: {
          limit: 20,
          page: 1,
          totalPages: 1,
          totalDocs: 0,
        },
        roles: [{ label: '', value: '' }],
      }
    case actions.GET_GROUPS_SUCCESS:
      const { groups } = action?.payload as IGetHotelGroupsResponse
      return {
        ...state,
        groups,
      }
    case actions.UPDATE_CSV:
      return { ...state, hotel: { ...state.hotel, ...action.payload } }
    default:
      return state
  }
}
