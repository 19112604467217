import * as action_types from './constants'

const initialState = {
  dashboardData: [],
  workshiftformData: [],
  ukgData: [],
  employeeData: [],
  workshiftData: [],
  sortingAttendants: {},
  singleEmployeeData: {},
  stepsPositions: '',
}

export default (state = initialState, action: any) => {
  const { type, data } = action
  switch (type) {
    case action_types.DASHBOARD_DATA:
      return { ...state, dashboardData: data }

    case action_types.WORKSHIFT_DATA:
      return { ...state, workshiftformData: data }

    case action_types.UKG_DATA:
      return { ...state, UKG_DATA: data }

    case action_types.EMPLOYEE_DATA:
      return { ...state, employeeData: data }

    case action_types.WORKSHIFT_DATA_SIDEBAR:
      return { ...state, workshiftData: data }

    case action_types.ATTENDANTS_FOR_SORTING:
      return { ...state, sortingAttendants: data }

    case action_types.WORKSHIFT_DATA_BY_ID:
      return { ...state, singleEmployeeData: data }

    case action_types.STEPPER_POSITION:
      return { ...state, stepsPositions: data }

    default:
      return state
  }
}
