import { AnyAction } from 'redux'

const actions = {
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  SET_STATE: 'auth/SET_STATE',
  SET_ERROR: 'auth/SET_ERROR',
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  SET_PASSWORD_EXPERATION_MESSAGE: 'auth/setPasswordExperationMessage',
}

export interface LoginFormType {
  email: string
  password: string
  remember?: boolean
}

export interface ResetPassword {
  password: string
  otp: string
  history: unknown
  redirectType: string
}

export const forgotPassword = (email: string): AnyAction => {
  return {
    type: actions.FORGOT_PASSWORD,
    payload: email,
  }
}

export const setPasswordConfirm = (value: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { passwordConfirm: value },
  }
}

export const resetPassword = (data: ResetPassword): AnyAction => {
  return {
    type: actions.RESET_PASSWORD,
    payload: data,
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const login = (payload: LoginFormType): AnyAction => {
  return {
    type: actions.LOGIN,
    payload,
  }
}

export const logout = (): AnyAction => {
  return {
    type: actions.LOGOUT,
  }
}

export const setError = (error: string | null): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { error },
  }
}

export const setPasswordExperationMessage = (message: string | null): AnyAction => {
  return {
    type: actions.SET_PASSWORD_EXPERATION_MESSAGE,
    payload: { passwordExperationMessage: message },
  }
}

export default actions
