// import OAuth from '@DevimaSolutions/o-auth'
import axios from '@/config/axiosInterceptor'
import { RoomResponseType, RoomsResponseType, RoomType } from '@/redux/rooms/types'
import { BodyChanges } from '@/redux/turndownAllocations/types'
import { AxiosResponse } from 'axios'

export const getRoomsWithAttendants = async (hotelID: string, date: string, type: string) => {
  const { data } = await axios.get(`getRoomsForAllocation/${hotelID}/${date}/${type}`)
  const newData: RoomResponseType = data
  return {
    totalFloors: newData.total_count,
    rooms: newData.floors?.reduce<Record<string, RoomType>>((acc, floor) => {
      return {
        ...acc,
        ...floor.rooms.reduce<Record<string, RoomType>>((carry, room) => {
          carry[room.id] = {
            ...room,
            floor: floor.floor_number,
          }
          return carry
        }, {}),
      }
    }, {}),
  }
}

export const getAutoAllocationService = async (
  hotelID: string,
  type: string,
): Promise<Record<string, string[]>[]> => {
  const { data } = await axios.get(`setAttendantAllocation/${hotelID}/${type}`)
  return data
}

export const saveChanges = async (type: string, body: BodyChanges): Promise<unknown> => {
  const { data } = await axios.post(`saveAllocations`, body)
  return data
}
